/* eslint-disable react/jsx-key */
import React, { Component, Suspense } from "react";
import {
  Button,
  ResponsiveTestimonialTab,
  MenuListV2Main,
  CustomListItemV2Main,
  Button2,
  TabData,
  WrapperContainer,
  RatingsContainers,
  Divider,
  PaddingContainer,
  RatingWrapper,
  TabsContainerTestimonial,
  AwarenessSection,
  CustomList,
  CoreWrapper,
  MainResources,
} from "./styles";
import {
  TabsContainerV2,
  MenuListV2Main2,
  /*ButtonBookDemo,*/ CoreList,
} from "../MainDashboardV3/styles";
import { ImageUrl, imgPath } from "../../utils/constants";
import PropTypes from "prop-types";
import data from "./wellnessResources.json";
import coreData from "./CoreFeature.json";
import { MenuLinks } from "../NavigationBarV2/styles";
import {
  ButtonGetStarted,
  WellBeing,
  Section,
  IntroTextContainer,
  ButtonLearnMoree,
  PosterImage,
  CoreFeatureSection,
  Resources,
  ButtonWatchVideo
} from "./styles";
import { connect } from "react-redux";
import { subscribeNewsLetter } from "../../redux/actions/authActions";
import testimonialData from "./TestimonialData.json";
import { COMPANY_NAME } from "../../utils/config";
import {
  ResourcesWrapper,
  RowWrapper,
  RowResponsiveWrapper,
} from "../PlatFormRecognition/styles";
import Waiting from "../Waiting";
const DemoPopup = React.lazy(() => import('../WhyAmigoCommon/DemoPopup'));
// import DemoPopup from "../common/LazyImage/LazyImage";
import LazyImage from "../common/LazyImage/LazyImage";

class AdminDashBoardPage extends Component {
  constructor() {
    super();
    this.state = {
      mobileViewStatus: window.innerWidth <= 500,
      active: "",
      selectedImage: "",
      selectedText: "WHY USERS LOVE US",
      selectedTab: 0,
      wellnessResources: JSON.parse(JSON.stringify(data)),
      image: [
        data[0].data[0].image,
        data[1].data[0].image,
        data[2].data[0].image,
      ],
      email: "",
      tabArray: [
        "Insurance",
        "Banking",
        "Technology",
        "Healthcare",
        "Civil Engineering",
      ],
      ratingImage: [
        testimonialData[0].imageURL,
        testimonialData[1].imageURL,
        testimonialData[2].imageURL,
        testimonialData[3].imageURL,
        testimonialData[4].imageURL,
      ],
      ratingData: [
        testimonialData[0].data,
        testimonialData[1].data,
        testimonialData[2].data,
        testimonialData[3].data,
        testimonialData[4].data,
      ],
      ratingName: [
        testimonialData[0].head,
        testimonialData[1].head,
        testimonialData[2].head,
        testimonialData[3].head,
        testimonialData[4].head,
      ],
      ratingMain: [
        testimonialData[0].main,
        testimonialData[1].main,
        testimonialData[2].main,
        testimonialData[3].main,
        testimonialData[4].main,
      ],
      selectedCoreTab: 0,
      selectedSubTab: 0,
      coreButtonText: [
        coreData[0].buttonText,
        coreData[1].buttonText,
        coreData[2].buttonText,
        coreData[3].buttonText,
        coreData[4].buttonText,
        coreData[5].buttonText,
      ],
      coreInfo2: [
        coreData[0].data2,
        coreData[1].data2,
        coreData[2].data2,
        coreData[3].data2,
        coreData[4].data2,
        coreData[5].data2,
      ],
      coreInfo: [
        coreData[0].data,
        coreData[1].data,
        coreData[2].data,
        coreData[3].data,
        coreData[4].data,
        coreData[5].data,
      ],
      coreHead: [
        coreData[0].head,
        coreData[1].head,
        coreData[2].head,
        coreData[3].head,
        coreData[4].head,
        coreData[5].head,
      ],
      coreName: [
        coreData[0].name,
        coreData[1].name,
        coreData[2].name,
        coreData[3].name,
        coreData[4].name,
        coreData[5].name,
      ],
      coreImage: [
        coreData[0].img,
        coreData[1].img,
        coreData[2].img,
        coreData[3].img,
        coreData[4].img,
        coreData[5].img,
      ],
      currentDiv: [true, false, false, false, false, false],
      showtab: 1,
      showVideo: false,
    };
  }

  componentDidMount() {
    this.setState({ active: "Insurance" });
  }

  changeModal = () => {
    this.setState({ showVideo: !this.state.showVideo });
  };

  top = () => (
    <Section firstChild margin="0">
      <IntroTextContainer>
        <h1>
          Employee wellness
          <br />
          that works
        </h1>
        <LazyImage div={"divider"} src={ImageUrl + "/images/WellnessRecipes/Rectangle.png"}/>
        <p>
          <p>
            {"With automation and real-time dashboards, you'll"}
            <br />
            {"maximize engagement and save hundreds of hours"}
            <br />
            managing your company programs.
          </p>
        </p>
        <div className="wrap"> 
          <ButtonWatchVideo onClick={() => this.changeModal()} style={{display:'flex',alignItems:"center",justifyContent:"center"}}>
            <LazyImage noMargin={1}  className='pink-triangle' src={ImageUrl + '/images/purple-triangle.png'}/><div style={{marginLeft:'8%'}}>Watch Video</div>
          </ButtonWatchVideo>
          <ButtonLearnMoree
            onClick={() => this.props.history.push("/contact")}
            noMargin={1}
          >
            REQUEST A DEMO
          </ButtonLearnMoree>
        </div>
      </IntroTextContainer>
      <PosterImage>
        {COMPANY_NAME == "Woliba" ? (
          <picture>
            <source
              srcSet={ImageUrl + "/images/adminDashboardPages/Admin_Hero_Image.webp"}
              type="image/png"
            />
            <LazyImage src={ImageUrl + "/images/adminDashboardPages/Admin_Hero_Image.webp"} title="Poster Image"/>
          </picture>
        ) : (
          <picture>
            <source
              srcSet={ImageUrl + "/images/HomePageV2/graphic-iphone.png"}
              type="image/png"
            />
            <LazyImage
              src={ImageUrl + "/images/HomePageV2/graphic-iphone.png"}
              title="Poster Image"
            />
          </picture>
        )}
      </PosterImage>
      <LazyImage
        src={ImageUrl + "/images/adminDashboardPages/adminMobileBannerImg.png"}
        title="Poster Image"
      />
    </Section>
  );

  ratingsUI = () => (
    <RatingWrapper>
      <RatingsContainers>
        <LazyImage div={"image"} src={ImageUrl + this.state.ratingImage[this.state.selectedTab]} alt={"rating"} />
        <LazyImage div={"ratingStars"} src={ImageUrl + "/images/HomePageV2/ratingStars.png"} alt={"rating"} />
        <div className={"ratingDescription"}>
          {this.state.ratingData[this.state.selectedTab]}
        </div>
        <div className={"ratingBy"}>
          {this.state.ratingName[this.state.selectedTab]}{" "}
          <span>{this.state.ratingMain[this.state.selectedTab]}</span>
        </div>
      </RatingsContainers>
    </RatingWrapper>
  );

  decreaseIndex = () => {
    const { selectedTab } = this.state;
    let temp = selectedTab - 1;
    this.setState({ selectedTab: temp });
  };

  increaseIndex = () => {
    const { selectedTab } = this.state;
    let temp = selectedTab + 1;
    this.setState({ selectedTab: temp });
  };

  handleTabs = (index) => {
    this.setState({ selectedTab: index });
  };

  renderMenuList = () => {
    let TabArray = [
      {
        tabName: "50-200 employees",
        image: "/path/",
        text: "Banking data",
      },
      {
        tabName: "201-500 employees",
        image: "/path/",
        text: "Technology Data",
      },
      {
        tabName: "501-1,000 employees",
        image: "/path/",
        text: "Civil Engineering data",
      },
    ];

    return (
      <TabsContainerTestimonial>
        <MenuListV2Main>
          {TabArray.map((data, index) => (
            <CustomListItemV2Main
              showTab={true}
              active={this.state.selectedTab === index}
              key={index}
              onClick={() => this.handleTabs(index)}
            >
              <MenuLinks><h3 style={{margin:0, fontSize:'20px'}}>{data.tabName}</h3></MenuLinks>
            </CustomListItemV2Main>
          ))}
        </MenuListV2Main>
      </TabsContainerTestimonial>
    );
  };

  renderTabData = () => (
    <TabData>
      <div className="responsiveWhyButton">
        <Button2
          display={"block"}
          onClick={() => this.props.history.push("/contact")}
        >
          BOOK A DEMO
        </Button2>
      </div>
      <LazyImage src={ImageUrl + "/images/adminDashboardPages/Powerful_realtime_data_Image.webp"} />
      <div>
        <h2>
          Powerful Real-time{this.state.mobileViewStatus ? "" : <br />}Data and
          analytics
        </h2>
        <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>
        <p>
          Wellness and engagement programs need to fit
          {this.state.mobileViewStatus ? "" : <br />} your culture to drive
          success, so our robust data{" "}
          {this.state.mobileViewStatus ? "" : <br />}
          allows you to be more proactive and confident <br /> in decisions.
        </p>
        <Button2
          display={"block"}
          onClick={() => this.props.history.push("/contact")}
        >
          REQUEST A DEMO
        </Button2>
      </div>
    </TabData>
  );

  handleResources = (resIndex, rowIndex) => {
    const { wellnessResources, image } = this.state;
    let array = wellnessResources;
    wellnessResources[resIndex].data.forEach((info, index) => {
      array[resIndex].data[index].status = info.status === 1 && 0;
    });
    array[resIndex].data[rowIndex].status = 1;
    let images = image;
    images[resIndex] = wellnessResources[resIndex].data[rowIndex].image;
    this.setState({ wellnessResources: array, image: images });
  };

  multipleFeatures = () => (
    <ResourcesWrapper>
      {this.state.wellnessResources.length > 0 &&
        this.state.wellnessResources.map((row, index) => (
          <MainResources key={index} color={row.color}>
            <h3>{row.heading}</h3>
            <LazyImage div={"line"} src={ImageUrl + "/images/HomePageV2/line.svg"}/>
            <Resources
              flexDirection={row.swapRow}
              col={row.color}
              align={index % 2 == 0 ? "flex-end" : "flex-start"}
            >
              <div>
                <div>
                  <LazyImage src={ImageUrl +"/images/" + `${row.icon}`} alt={row.title}/>
                  <h4 className={"title"}>{row.title}</h4>
                </div>
                <div className="normal">
                  {row.data.map((info, rowindex) => (
                    <RowWrapper
                      key={rowindex}
                      selected={info.status}
                      bgColor={row.bgColor}
                      onClick={() => this.handleResources(index, rowindex)}
                      customColor={info.status === 1 ? 1 : 0}
                    >
                      <div className={"header"}>
                        <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                        <div>{info.header}</div>
                        <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                      </div>
                      {info.status === 1 && (
                        <div className={"expand"}>{info.description}</div>
                      )}
                    </RowWrapper>
                  ))}
                </div>
                <div className="responsive">
                  {row.data.map((info, rowindex) => (
                    <div>
                      <RowResponsiveWrapper
                        key={rowindex}
                        selected={info.status}
                        bgColor={row.bgColor}
                        onClick={() => this.handleResources(index, rowindex)}
                        customColor={info.status === 1 ? 1 : 0}
                      >
                        <div className={"header"}>
                          <span><LazyImage src={ImageUrl +"/images/" + `${info.icon}`} alt={row.title}/></span>
                          <div>{info.header}</div>
                          <LazyImage div={"downArrow"} src={ImageUrl +"/images/WellnessPage/Vector (1).svg"} alt={"arrow"}/>
                        </div>
                        {info.status === 1 && (
                          <div className={"expand"}>{info.description}</div>
                        )}
                      </RowResponsiveWrapper>
                      {info.status === 1 && (
                        <LazyImage div={"responsive-image"} src={ImageUrl + "/images/" + this.state.image[index]}/>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <LazyImage div={"image"} src={ImageUrl + "/images/" + this.state.image[index]}/>

            </Resources>
          </MainResources>
        ))}
    </ResourcesWrapper>
  );
  coreFeatureSection = () => (
    <CoreFeatureSection>
      <div>
        <h2>Admin Resources</h2>
        <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>
      </div>
    </CoreFeatureSection>
  );

  awarenessUI = () => (
    <AwarenessSection>
      <div className="responsiveAwarenessButton" />
      <LazyImage div={"img"} src={ImageUrl + "/images/WellnessPage/Group.svg"} /> 
      <div className="text">
        <p>
          Employees using Woliba
          <br />
          {"say we've helped them increase"}
          <br />
          their awareness for personal
          <br />
          health.
        </p>
        <div className="responsivetext">
          <p>
            {
              "Employees using Woliba say we've helped them increase their awareness for personal health."
            }
          </p>
        </div>
        <Button
          display={"block"}
          onClick={() => this.props.history.push("/contact")}
        >
          GET STARTED
        </Button>
      </div>
    </AwarenessSection>
  );

  onEmailChange = (e) => {
    this.setState({ email: e.target.value });
  };

  divider = () => (
    <Divider>
      <img src={`${imgPath}/WellnessPage/blueDivider.png`} alt={"breaker"} />
    </Divider>
  );
  onSubmit = (e) => {
    e.preventDefault();
    const { subscribeNewsLetter, history } = this.props;
    const { email } = this.state;
    let payload = {
      email,
    };
    if (email.trim() !== "") {
      subscribeNewsLetter(history, payload);
      this.setState({
        email: "",
      });
    }
  };

  renderResponsiveTab = () => (
    <ResponsiveTestimonialTab>
      {this.state.selectedTab > 0 ? 
        this.state.selectedTab > 0 && <LazyImage div="leftArrow" onClick={() => this.decreaseIndex()} src={ImageUrl + "/images/HomePageV2/leftArrow.png"} />
        : <div className="leftArrow"></div>}
      <div>
        <p>
          {
            this.state.tabArray[this.state.selectedTab]
          }
        </p>
      </div>
      {this.state.selectedTab < 4 && 
          this.state.selectedTab < 4 && <LazyImage div="rightArrow" onClick={() => this.increaseIndex()} src={ImageUrl + "/images/HomePageV2/rightArrow.png"} /> }
    </ResponsiveTestimonialTab>
  );

  wellBeingSection = () => (
    <WellBeing>
      <div>
        <div>
          <p>
            Are you ready to <br />
            create a culture of <br />
            wellbeing?
          </p>

          <ButtonGetStarted
            display={"block"}
            onClick={() => this.props.history.push("/contact")}
          >
            GET STARTED
          </ButtonGetStarted>
        </div>
      </div>
      <LazyImage src={ImageUrl + "/images/HomePageV2/Schedule_a_demo.webp"} />
    </WellBeing>
  );

  wellnessPageRedirection = (index) => {
    if (index === 0) {
      this.props.history.push("/corporate-wellbeing-software-v2");
    }
    if (index === 1) {
      this.props.history.push("/nutrition-recipes");
    }
    if (index === 3) {
      this.props.history.push("/wellness-education");
    }
    if (index === 2 || index === 4 || index === 5) {
      this.props.history.push("/contact");
    }
  };

  handleSubTabs = (index) => {
    this.setState({ selectedSubTab: index });
  };

  handleSubResponsiveTabs = (resIndex) => {
    const { currentDiv } = this.state;
    let array = currentDiv;
    array.forEach((data, index) => {
      array[index] = false;
    });
    array[resIndex] = true;
    this.setState({ selectedCoreTab: resIndex });
    this.setState({ currentDiv: array });
    if (resIndex === this.state.selectedCoreTab) {
      this.setState({ showtab: !this.state.showtab });
    }
  };

  coreFeature = () => {
    let SubTabArray = [
      {
        tabName: "Reports",
        image: "/path/",
      },
      {
        tabName: "Fitness",
        image: "/path/",
      },
      {
        tabName: "Recognition",
        image: "/path/",
      },
      {
        tabName: "Surveys",
        image: "/path/",
      },
      {
        tabName: "Biometrics",
        image: "/path/",
      },
      {
        tabName: "Monthly Tasks",
        image: "/path/",
      },
    ];
    return (
      <CoreWrapper>
        <div>
          <h2>Tools that simplify Work and Save time</h2>
          <LazyImage src={ImageUrl + "/images/HomePageV2/line.svg"}/>
        </div>
        <div>
          <TabsContainerV2>
            <MenuListV2Main2>
              {SubTabArray.map((data, index) => (
                <CustomList
                  showTab={true}
                  active={this.state.selectedSubTab === index}
                  key={index}
                  onClick={() => this.handleSubTabs(index)}
                >
                  <MenuLinks><h3 style={{margin:0, fontSize:'20px'}}>{data.tabName}</h3></MenuLinks>
                </CustomList>
              ))}
            </MenuListV2Main2>
          </TabsContainerV2>
        </div>
        <div>
          <div>
            <div className="box-top-line" >
              <p>{this.state.coreInfo[this.state.selectedSubTab]}</p>
              <br />
              <p>{this.state.coreInfo2[this.state.selectedSubTab]}</p>
            </div>
            {/* <BookDemo onClick={() => this.wellnessPageRedirection(this.state.selectedSubTab)} 
              noMargin={1}>{this.state.coreButtonText[this.state.selectedSubTab]}
              <i className="fas fa-chevron-right" style={{"marginLeft": "10px"}}></i>
            </BookDemo> */}
          </div>
          <LazyImage src={ImageUrl + this.state.coreImage[this.state.selectedSubTab]}/>
        </div>
        <div className="responsive-core">
          {SubTabArray.map((data, index) => (
            <CoreList
              active={
                this.state.selectedCoreTab === index && this.state.showtab
                  ? 1
                  : 0
              }
              key={index}
              onClick={() => this.handleSubResponsiveTabs(index)}
            >
              <div style={{alignItems:'center'}}>
                <h3>{data.tabName}</h3>
                <LazyImage src={ImageUrl + "/images/HomePageV2/down.png"}/> 
              </div>
              {this.state.currentDiv[index] && this.state.showtab && (
                <div className="selectedCoreContent">
                  <div>
                    <h2>{this.state.coreHead[this.state.selectedCoreTab]}</h2>
                    <LazyImage src={ImageUrl + "/images/HomePageV2/Rectangle.png"}/>
                    <div>
                      <p>{this.state.coreInfo[this.state.selectedCoreTab]}</p>
                      <br />
                      <p>{this.state.coreInfo2[this.state.selectedCoreTab]}</p>
                    </div>
                    {/* <ButtonBookDemo onClick={()=> this.wellnessPageRedirection(this.state.selectedCoreTab)} 
                      noMargin={1}>{this.state.coreButtonText[this.state.selectedCoreTab]}</ButtonBookDemo> */}
                  </div>
                  <LazyImage src={ImageUrl + this.state.coreImage[this.state.selectedSubTab]}/>
                </div>
              )}
              <LazyImage div={"coreDivider"} src={ImageUrl + "/images/HomePageV2/CoreDivider.png"}/>
            </CoreList>
          ))}
        </div>
      </CoreWrapper>
    );
  };

  render() {
    const { showVideo } = this.state;
    return (
      <WrapperContainer>
        {this.top()}
        {this.ratingsUI()}
        {this.renderResponsiveTab()}
        <PaddingContainer>{this.renderMenuList()}</PaddingContainer>
        {this.renderTabData()}
        <PaddingContainer>{this.coreFeatureSection()}</PaddingContainer>
        <PaddingContainer>{this.multipleFeatures()}</PaddingContainer>
        {this.coreFeature()}
        {this.wellBeingSection()}
        {showVideo && (
          <Suspense fallback={<Waiting />}>
            <DemoPopup
              showModal={showVideo}
              onClose={this.changeModal}
              videoUrl={"https://vimeo.com/808590329"}
              VideoTitle={"Woliba Wellness Admin"}
            />
          </Suspense>
        )}
      </WrapperContainer>
    );
  }
}
AdminDashBoardPage.propTypes = {
  history: PropTypes.object,
  subscribeNewsLetter: PropTypes.func,
};
const mapDispatchToProps = (dispatch) => ({
  subscribeNewsLetter: (history, payload) =>
    dispatch(subscribeNewsLetter(history, payload)),
});
export default connect(null, mapDispatchToProps)(AdminDashBoardPage);