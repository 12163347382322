import styled from 'styled-components';
import {Tabs} from "react-bootstrap";

const StyledTabContainer = styled(Tabs)`
   float: left;
  > ul {
    height: ${({newPadding}) => newPadding ? '70px' : 'none' };
    border-bottom: none;
    padding: ${({newPadding}) => newPadding ? '16px 10px 12px 18px' : '20px 20px 1px 33px' };
    font-size: 12px;
    text-transform: capitalize;
    color: rgb(153,153,153);
    background-color: #fff;
    
    > li {
      > a {
        font-size: 12px;
        letter-spacing: 0px;
        color: rgb(153,153,153);
        font-family: 'rubik-bold';
        margin-right: 5vw;
        text-transform: capitalize;
        padding: 5px 5px 17px 5px;
        
        &:hover, &:active, &:focus {
          outline: none;
          text-decoration: none;
          background-color: transparent;
          border-color: transparent;
        }
      }
    }
    
    .active {
      border-bottom: none;
      > a {
        background-color: transparent;
        color: rgb(21,159,202);
        border: none;
        border-bottom: 3px solid rgb(21,159,202);
        
        &:hover, &:active, &:focus {
          outline: none;
          text-decoration: none;
          background-color: transparent;
          border: none;
          border-bottom: 3px solid rgb(21,159,202);
          color: rgb(21,159,202);
        }
      }
    }
  }
`;


const StyledTabs = styled(StyledTabContainer)`
background:white;
padding:15px;
  width: 100%;
  .tab-content {
    position: relative;
  }
  >button{
      width: 50px;
      height: 40px;
      background: red;
  }
  .company-container {
    float: right;
    margin-top: -53px;
    margin-right: 43px;
    
    @media (max-width: 639px) {
      float: left;
      margin-left: 63px;
    }
    @media (max-width: 800px) {
      float: right;
      
    }
    @media (max-width: 500px) {
      display: inline-block;
      float: none;
      margin: -27px 41px 0 33px;
      padding-top: 10px;
    }
    @media (max-width: 600px) and (min-width: 501px) {
        display: inline-block;
        float: right;
        margin: 0 41px 0 33px;
        padding-top: 10px;
    }
  }
  
  > ul {
    margin: 0
    border-radius: 6px;
    padding: 15px;
    @media (max-width: 600px) {
      min-height: 100px;
      margin-top: 30px;
      padding: 4px 0px;
    }
    > li {
      min-width: 12%;
      display: flex;
      justify-content: center;
      height: 40px;
      margin-right: 25px;
      cursor: pointer;
      border-radius: 6px;
      @media (max-width: 600px) {
        text-align: center;
        float: none;
        width: 107px;
        margin: 0 auto;
      }
      > a {
        font-size: 15px;
        color: #CCCBDO;
        text-transform: none;
        font-family: 'rubik-bold';
        margin-right: 0px;
        display: flex;
        align-items: center;
        @media (max-width: 600px) {
        padding-left: 0;
        padding-right: 0
        margin: 0;
        padding: 10px 0px;

        font-family: 'Rubik-Regular';
        font-size: 18px;
        line-height: 21px;
        color:#9C9C9C;
       }
      }
    }

    .active {
      > a {
        &:hover, &:active, &:focus {
          color: rgb(51,51,51);
        }
      }
    }
  }

  li.nav-item {
      @media (max-width: 600px) {
        float: none;
        width: 135px;
        margin: 0 auto;
      }
      >button {
				margin-right: 3vw;
        font-size: 16px;
        color: rgb(156, 156, 156);
        text-transform: none;
        font-family: Rubik-Regular;
        
        @media (max-width: 600px) {
        padding-left: 0;
        padding-right: 0
        margin: 0
       }
      }
    }

  li.nav-item {
    button.first-tab.nav-link.active  {
    background: rgba(0, 47, 71, 0.1);
    border-radius: 6px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: #0D4270;
    justify-content: center;
    padding: 10px;
    border-bottom: 3px solid  none;
    border: none;
    margin-top: -5px;
    }
  }
  li.nav-item {
    button.second-tab.nav-link.active  {
    background: rgba(0, 47, 71, 0.1);
    border-radius: 6px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: #0D4270;
    justify-content: center;
    padding: 10px;
    border-bottom: 3px solid  none;
    border: none;
    margin-top: -5px;
    }
  }

  li.nav-item {
    button.third-tab.nav-link.active  {
    background: rgba(0, 47, 71, 0.1);
    border-radius: 6px;
    font-family: 'Rubik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-align: center;
    color: #0D4270;
    justify-content: center;
    padding: 10px;
    border-bottom: 3px solid  none;
    border: none;
    margin-top: -5px;
    }
  }

  li.first-tab.active {
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color:#0D4270;
    }
  }
  li.second-tab.active {
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color:#0D4270;
    }
  }
  
  li.third-tab.active {
    > a {
      border-bottom: none;
      padding: 10px 0px;
      padding: 0px;
      display: flex;
      align-items: center;
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px;
      color:#0D4270;
    }
  }
  li.first-tab.active {
    background: rgba(0, 47, 71, 0.1);
    border-radius: 6px;
  }
  li.second-tab.active {
    background: rgba(0, 47, 71, 0.1);
    border-radius: 6px;
  }
  li.third-tab.active {
    background: rgba(0, 47, 71, 0.1);
    border-radius: 6px;
  }
  > ul > li > a{
    padding: 0px;

    font-family: 'Rubik-Regular';
    font-size: 16px;
    line-height: 20px;
    color:#9C9C9C;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
  height: 0px;
`;

const CompanyButton = styled.div`
width: 175px;
height: 40px;
background: rgba(253, 113, 117, 1);
font-family: 'Rubik-Medium';
font-size: 18px;
line-height: 24px;
color: #FFF;
display: flex;
justify-content: center;
align-items: center;
margin-right: 15px;
position: relative;
top: 15px;
border-radius: 6px;
cursor: pointer;
`;

export{ StyledTabs, StyledTabContainer, CompanyButton, ButtonContainer};
