import React, { Suspense } from "react";
import PropTypes from 'prop-types';
import { DashboardLayout, HeaderWrapper, OverlayDashboard } from '../CompanyDashBoardV2/styles';
const CompanyDetails = React.lazy(() => import("../CompanyDashBoardV2/CompanyDetails"))
import { HeaderWrapperV2 } from '../CompanyDashBoardV2/styles';
import { /*StyledTabs,*/ CompanyButton, ButtonContainer} from './styles';
// import { Tab } from "react-bootstrap";
const DailyTipContainer = React.lazy(() => import("../DailyTipAdmin/index"))
import {fetchTipCategoryCount, fetchDailyTipAndQuoteCount} from '../../redux/actions';
import {connect} from 'react-redux';
import Waiting from '../Waiting';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import moment from 'moment';
import {TabsStyle, TabsWrapper} from '../../components/common/commonStyles';

class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 1,
      companyId: props.companyInfo.id,
      year: moment().format('YYYY')
    }
  }

  componentDidMount(){
    const { fetchTipCategoryCount, fetchDailyTipAndQuoteCount} = this.props;
    const { companyId, year } = this.state;
    fetchTipCategoryCount();
    fetchDailyTipAndQuoteCount(companyId, year);
  }

  setActiveTab = (active) => {
    this.setState({ selectedTab: active })
  }

  render() {
    const { role, companies, history , tipCategoryCount, tipQuoteCount} = this.props;
    const { selectedTab} = this.state;
    if(isNull(tipCategoryCount)|| isUndefined(tipCategoryCount) || isNull(tipQuoteCount) || isUndefined(tipQuoteCount)) {
      return <Waiting />
    }
    return (
      <OverlayDashboard>
        <DashboardLayout>
          {role === "ADMIN" ? (
            <HeaderWrapperV2 padding={1}>
              <div style={{ marginBottom: "25px" }} />
              {role === "ADMIN" &&<ButtonContainer>
                <CompanyButton onClick={() => history.push('/company/create_company')}>Create Company</CompanyButton>
              </ButtonContainer>}
              {role === "ADMIN" &&
                <TabsWrapper >
                  <TabsStyle  onClick={()=>this.setActiveTab(1)} active={selectedTab===1}>
                    Overview
                  </TabsStyle>
                  <TabsStyle  onClick={()=>this.setActiveTab(2)} active={selectedTab===2}>
                    Tips & Quotes
                  </TabsStyle>
                </TabsWrapper>}
              {
                selectedTab===1?<Suspense fallback={<Waiting/>}> <CompanyDetails history={history} role={role} companies={companies} /> </Suspense>:<Suspense fallback={<Waiting/>}><DailyTipContainer history={history} tipCategoryCount={tipCategoryCount} tipQuoteCount={tipQuoteCount}/></Suspense>
              }
            </HeaderWrapperV2>
          ) : (
            <HeaderWrapper />
          )}
        </DashboardLayout>
      </OverlayDashboard>
    );
  }
}
AdminDashboard.propTypes = {
  networkError: PropTypes.string,
  companies: PropTypes.array,
  companyInfo: PropTypes.object.isRequired,
  setCompany: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  history: PropTypes.object,
  fetchTipCategoryCount: PropTypes.func,
  tipCategoryCount: PropTypes.array,
  fetchDailyTipAndQuoteCount: PropTypes.func,
  tipQuoteCount: PropTypes.array
};

const mapStateToProps = (state) => ({
  tipCategoryCount: state.companyDashboard.tipCategoryCount,
  tipQuoteCount:state.companyDashboard.tipQuoteCount
});

const mapDispatchToProps = (dispatch) => ({
  fetchTipCategoryCount: () => dispatch(fetchTipCategoryCount()),
  fetchDailyTipAndQuoteCount: (id, year) => dispatch(fetchDailyTipAndQuoteCount(id, year))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
